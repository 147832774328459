/**
 * 配置环境变量的统一入口
 */
// api
// export const baseUrl = process.env.VUE_APP_BASE_URL
let baseURL = process.env.NODE_ENV === 'production' ? window.IPconfig.baseURL : process.env.VUE_APP_BASE_URL
let loginURL = process.env.NODE_ENV === 'production' ? window.IPconfig.loginURL : process.env.VUE_APP_LOGIN_BASE_URL
let imgURL = process.env.NODE_ENV === 'production' ? window.IPconfig.imgURL : process.env.VUE_APP_IMG_BASE_URL
let prodURL = process.env.NODE_ENV === 'production' ? window.IPconfig.prodURL : 'https://m.data.jiot.link/#/'

export const baseUrl = baseURL

// 登录api
// export const loginUrl = process.env.VUE_APP_LOGIN_BASE_URL

export const loginUrl = loginURL


// 图片
// export const imgUrl = process.env.VUE_APP_IMG_BASE_URL
export const imgUrl = imgURL

// 七牛云
export const QiNiuUrl = process.env.VUE_APP_QI_NIU_URL

// 正式地址
// export const prodUrl = 'https://m.data.jiot.link/#/'
export const prodUrl = prodURL
